import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class CommercialDroneInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Commercial Drone Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "If you are using a drone to make money or as part of any business operation in Louisiana, drone insurance (unmanned aerial vehicle insurance) is vital. Contact us for a quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Drone flying at chemical plant."/>
        <Callout
          title="Commercial Drone Insurance"
          content="If you're using a drone to make money or as part of any business operation in Louisiana, drone insurance (unmanned aerial vehicle insurance) is vital. This will help your company be able to make use of the benefits of having a drone while offsetting risks associated with it."
        />
        <div className="site-body page-content">
          <h2>What Drone Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Property Damage & Bodily Injury.</strong> Typically this liability covers damage to property and persons with limits starting at $500,000 to north of $10,000,000 per occurrence.</li>
          <li><strong>Hull (Drone Damage).</strong> This covers the cost associated with damage to your drone from business use, based on its “agreed value."</li>
          <li><strong>Payload.</strong> This covers damage to equipment the drone is carrying, such as a camera.</li>
          <li><strong>Personal Injury.</strong> This will usually cover libel, slander, violation of privacy, and copyright infringements associated with use of the drone.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="By the end of 2018, there are expected to be over 600,000 Commercial Drones in use for different business operations."
            source="Federal Aviation Administration"
          />
          <h2>What Drone Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Personal Damage.</strong> Damage to the drone, property, or a person from personal leisure use.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Commercial Drone Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default CommercialDroneInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "commercial-drone-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-14.jpg" }) {
      ...mainBg
    }
  }
`;
